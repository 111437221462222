import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../assets/style/core.css"
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { faArrowUpFromBracket, faAward, faBrain, faCameraAlt, faCircleExclamation, faClover, faEarListen, faFaceFrownOpen, faFaceSmileBeam, faFaceSmileWink, faFaceSurprise, faFlagCheckered, faImage, faMessage, faMugHot, faPeace, faPeopleGroup, faPhone, faRightToBracket, faShekelSign, faShuttleSpace, faSmileWink, faSurprise, faTrophy, faUserNinja } from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import comImg from "../assets/img/communication.png"

function Communication() {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <Navbar />
            <section>
                <div className='container'>
                    <div className='p-md-5 p-3'>
                        <h1 className='text-primary text-center mb-1 mt-4'>SKILLOVATE - Empower your voice, Enhance your reach : Communicate with clarity!</h1>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="hero-content darkBlue-text" data-aos="fade-right" data-aos-duration="1200">
                                    <p>"Skillovate" is a sub-event dedicated to honing communication skills. Participants will partake in various exercises and sessions designed to enhance both verbal and non-verbal communication, active listening, and the art of delivering compelling presentations. Foster a spirit of collaboration and teamwork by working  together with other participants. </p>

                                    <p>Whether you aspire to be a dynamic public speaker, a charismatic presenter, or a persuasive negotiator, this conclave will equip you with the tools to succeed. Join us and harness the power of communication to make a lasting impression and propel your career to new heights.</p>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                <img src={comImg} height={420} data-aos="fade-up" data-aos-duration="1800" className='img-ani' />
                            </div>
                        </div>
                        <h4 className='darkBlue-text'>Rules and Regulations</h4>
                        <ul>
                            <li><span class="darkBlue-text">Pair of 2 participants.</span></li>
                            <li><span class="darkBlue-text">Judges decision will be final.</span></li>
                        </ul>
                    </div>
                </div>

                <div className='hero-wrapper'>
                    <div className="container">
                        <div className="p-md-5 text-center mt-5">
                            <div className="row">
                                <h1 className='mb-5 text-white mt-5'>Pre Requisite</h1> 
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1800">
                                    <p className="h5 mt-2 mb-4 text-white">One laptop with each team and pair of earphones</p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1400">
                                    <p className="h5 mt-2 mb-4 text-white">Pre event assignment will be given
                                    </p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1000">
                                    <p className="h5 mt-2 mb-4 text-white">Confidence and assertiveness
                                    </p>
                                </div>
                            </div>
                            <h1 className='mb-3 text-white mt-4 text-decoration-underline'>Contact / Help</h1>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Student Coordinator : </h2>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Mr. - Shoaib Bepari <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/8123027104"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Miss. Maheshwari Chavan  - <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/7892167689"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                </div>
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Faculty Coordinator : </h2>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Prof. Sushma N  </h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Prof. Uttara R  </h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Prof. Bhavani  </h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Prof. Nikitha  </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className='text-center p-5 darkBlue-text text-decoration-underline'>Master the Art of Communication: Harness your words, connect deeply, and leave a lasting impression in every interaction.</h4>
            </section>
            <Footer />
        </>
    )
}

export default Communication