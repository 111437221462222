import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../assets/style/core.css"
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { faArrowUpFromBracket, faAward, faBrain, faCircleExclamation, faFaceSmileBeam, faFaceSmileWink, faFaceSurprise, faFlagCheckered, faMugHot, faPeopleGroup, faPhone, faRightToBracket, faShuttleSpace, faSmileWink, faSurprise, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coding from "../assets/img/coding.png"

function Coding() {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <Navbar />
            <section>
                <div className='container'>
                    <div className='p-md-5 p-3'>
                        <h1 className='text-primary text-center mb-1 mt-4'>NEOBYTES - Where Imagination Rules, Logic Reigns, and Possibilities Know No Bounds</h1>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="hero-content darkBlue-text" data-aos="fade-right" data-aos-duration="1200">
                                    <p>Embrace the world of coding and conquer your fears at our special coding event tailored for beginners!
                                        Are you curious about the power of programming but feel <span className='fw-bolder text-primary'>hesitant</span> to take the first step? Don't worry! This event is designed to  <span className='fw-bolder text-primary'>empower</span> students with no prior programming knowledge and eradicate any fears that may be holding you back.</p>

                                    <p>This opportunity is a safe space for you to <span className='fw-bolder text-primary'>explore, experiment, and learn </span>at your own pace.</p> <p className='fw-bolder text-primary text-center'>We believe that anyone can become a programmer, regardless of their background or initial knowledge.</p>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                <img src={coding} className=' img-ani' height={420}
                                    data-aos="fade-up" data-aos-duration="1800" />
                            </div>
                        </div>
                        <h4 className='darkBlue-text'>Rules and Regulations</h4>
                        <ul>
                            <li className='darkBlue-text'>Two participants</li>
                            <li className='darkBlue-text'>Laptop with
                                Installed editors like VsCode, Eclipse, pyCharm, Dev C++ is mandatory</li>
                            <li className='darkBlue-text'>Judges' decision is final.</li>
                            <li className='darkBlue-text'>Discipline must be maintained throughout the event.</li>
                        </ul>
                    </div>
                </div>

                <div className='hero-wrapper'>
                    <div className="container">
                        <div className="p-md-5 text-center mt-5">
                            <div className="row">
                                <h1 className='mb-5 text-white mt-5'>Pre Requisite</h1>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="800">
                                    <p className="h5 mt-2 mb-4 text-white">        Proficiency in programming languages (Python, Java, C++).
                                    </p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1400">
                                    <p className="h5 mt-2 mb-4 text-white">Zero         Understanding of algorithms, data structures, and SQL.
                                    </p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1000">
                                    <p className="h5 mt-2 mb-4 text-white"> Basic JavaScript skills.</p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1800">
                                    <p className="h5 mt-2 mb-4 text-white">Python data analysis (NumPy, Pandas, Matplotlib).</p>
                                </div>
                            </div>
                            <h1 className='mb-3 text-white mt-4 text-decoration-underline'>Contact / Help</h1>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Student Coordinator : </h2>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Mr. Vipul Jujar - <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/9591074133"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Mr. Sadanand Lalagi - <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/8147509548"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                </div>
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Faculty Coordinator : </h2>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Sharada M </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Vaishnavi S </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Varuni </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Pooja </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className='text-center p-5 darkBlue-text text-decoration-underline'>Why participate? Because coding is the closest thing to having a superpower. Forget about flying or invisibility; we're talking about creating something out of nothing with just lines of code!</h4>
            </section>
            <Footer />
        </>
    )
}

export default Coding