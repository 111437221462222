import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../assets/style/core.css"
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { faArrowUpFromBracket, faAward, faBrain, faCircleExclamation, faFaceSmileBeam, faFaceSmileWink, faFaceSurprise, faFlagCheckered, faMugHot, faPeopleGroup, faPhone, faRightToBracket, faShuttleSpace, faSmileWink, faSurprise, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hack from "../assets/img/hackathon.png"

function Hackathon() {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <Navbar />
            <section>
                <div className='container'>
                    <div className='p-md-5 p-3'>
                        <h1 className='text-primary text-center mb-1 mt-4'>BIZZATHON - The ultimate battle of ideas and innovation</h1>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="hero-content darkBlue-text" data-aos="fade-right" data-aos-duration="1200">
                                    <p>Step into the world of business strategy and innovation with Bizzathon, the ultimate challenge for aspiring tech entrepreneurs. Participants will compete in teams to develop groundbreaking business ideas, craft compelling pitches, and devise strategic plans. Participants should have knowledge about Problem solving skills, Technology and tools, Effective Presentation skills.</p>
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                <img src={Hack} className=' img-ani' height={420}
                                data-aos="fade-up" data-aos-duration="1800"/>
                            </div>
                        </div>
                        <h4 className='darkBlue-text'>Rules and Regulations</h4>
                        <ul>
                        <li className='darkBlue-text'>Two participants</li>
                        <li className='darkBlue-text'>Each participant should carry their own laptops</li>
                        <li className='darkBlue-text'>Judges' decision is final.</li>
                        <li className='darkBlue-text'>Discipline must be maintained throughout the event.</li>
                    </ul>
                    </div>
                </div>

                <div className='hero-wrapper'>
                    <div className="container">
                        <div className="p-md-5 text-center mt-5">
                            <div className="row"> 
                                <h1 className='mb-5 text-white mt-5'>Pre Requisite</h1>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="800">
                                    <p className="h5 mt-2 mb-4 text-white">        Creativity and Collaboration
</p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1400">
                                    <p className="h5 mt-2 mb-4 text-white">Adaptability and Resilience </p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1000">
                                    <p className="h5 mt-2 mb-4 text-white"> Time Management Skills</p>
                                </div>
                                <div className="col-sm" data-aos="zoom-in" data-aos-duration="1800">
                                    <p className="h5 mt-2 mb-4 text-white">Problem-Solving and attentive Mindset</p>
                                </div>
                            </div>
                            <h1 className='mb-3 text-white mt-4 text-decoration-underline'>Contact / Help</h1>
                            <div className="row"> 
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Student Coordinator : </h2>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Mr.Nikhil Raikar - <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/7899272068"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                    <h5 className='text-white' data-aos="zoom-in" data-aos-duration="800"> Mr. Abhishek B - <a className="text-white me-3 btn btn-sm  btn-secondary" href="https://wa.me/9845135061"><FontAwesomeIcon icon={faWhatsapp} size="lg" /></a></h5>
                                </div>
                                <div className="col-lg-6">
                                    <h2 className='text-white mt-3'>Faculty Coordinator : </h2>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Hema C </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800">  Prof. Shobha   </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Anil D </h5>
                                    <h5 className='text-white mb-4' data-aos="zoom-in" data-aos-duration="800"> Prof. Jyothi K </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className='text-center p-5 darkBlue-text text-decoration-underline'>Break barriers, write code, and conquer challenges at our hackathon!</h4>
            </section>
            <Footer />
        </>
    )
}

export default Hackathon