import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import GameDesc from './pages/GameDesc';
import QuizDesc from './pages/QuizDesc';
import CommDesc from './pages/CommDesc';
import ArtDesc from './pages/ArtDesc';
import PaintDesc from './pages/PaintDesc';
import Registration from './components/Registration';
import Team from './pages/Team';
import Coding from './events/Coding';
import Communication from './events/Communication';
import Gaming from './events/Gaming';
import Designing from './events/Designing';
import Hackathon from './events/Hackathon';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coding" element={<Coding />} /> 
        <Route path="/communication" element={<Communication />} /> 
        <Route path="/gaming" element={<Gaming />} /> 
        <Route path="/designing" element={<Designing />} /> 
        <Route path="/hackathon" element={<Hackathon />} /> 

        <Route path="/registration" element={<Registration />} />
        <Route path="/team" element={<Team />} />
      </Routes>
    </Router>
  );
}

export default App;
