import React from 'react'
import "../assets/style/core.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro, faClipboardQuestion, faCode, faComment, faCommentAlt, faComments, faGamepad, faGem, faPaintBrush, faPenNib, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import coding from "../assets/img/coding.png"
import gaming from "../assets/img/gaming.png"
import communication from "../assets/img/communication.png"
import hackathon from "../assets/img/hackathon.png"
import designing from "../assets/img/designing.png"

function Events() {
    return (
        <>
            {/* Events */}
            <section className="hero-wrapper">
                <div className="p-md-5 p-3 mt-5">
                    <h1 className="text-center text-white mt-4" id="events">Events</h1>
                    <div className="container">
                        <div className="row">
                            {/* Codings */}
                            <div className="col-xl-4 col-lg-6 mt-4">
                                <div className="card card-stats mb-4 mb-xl-0" data-aos="fade-right" data-aos-duration="800">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col text-center">
                                                <img src={coding} className='img-fluid' height={300}/>
                                                <p className="font-weight-bold mb-1 darkBlue-text">Coding</p>
                                                <span className="h2 font-weight-bold mb-0 darkBlue-text">NEOBYTES</span>
                                            </div> 
                                            <div className='text-center'>
                                                <Link to='/coding' className='btn btn-md custom-btn mt-3'>Know More</ Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Quiz */}
                            
                            <div className="col-xl-4 col-lg-6 mt-4">
                                <div className="card card-stats mb-4 mb-xl-0" data-aos="fade-right" data-aos-duration="1600">
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col text-center">
                                            <img src={gaming} className='img-fluid' height={300}/>
                                                <p className="font-weight-bold mb-1 darkBlue-text">Gaming</p>
                                                <span className="h2 font-weight-bold mb-0 darkBlue-text">VIRTUFUSE</span>
                                            </div> 
                                            <div className='text-center'>
                                                <Link to='/gaming' className='btn btn-md custom-btn mt-3'>Know More</ Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 mt-4">
                                <div className="card card-stats mb-4 mb-xl-0" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col text-center">
                                            <img src={designing} className='img-fluid' height={300}/>
                                                <p className="font-weight-bold mb-1 darkBlue-text">Designing and Photography</p>
                                                <span className="h2 font-weight-bold mb-0 darkBlue-text">DESIGNEX</span>
                                            </div> 
                                            <div className='text-center'>
                                                <Link to='/designing' className='btn btn-md custom-btn mt-3'>Know More</ Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Communication */}
                            {/* Gaming */}
                            <div className="col-xl-4 col-lg-6 mt-4">
                                <div className="card card-stats mb-4 mb-xl-0" data-aos="fade-left" data-aos-duration="1800">
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col text-center">
                                            <img src={communication} className='img-fluid' height={300}/>
                                                <p className="font-weight-bold mb-1 darkBlue-text">Communication</p>
                                                <span className="h2 font-weight-bold mb-0 darkBlue-text">SKILLOVATE</span>
                                            </div> 
                                            <div className='text-center'>
                                                <Link to='/communication' className='btn btn-md custom-btn mt-3'>Know More</ Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Painting */}
                            <div className="col-xl-4 col-lg-6 mt-4 " data-aos="fade-right" data-aos-duration="2200">
                                <div className="card card-stats mb-4 mb-xl-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col text-center">
                                            <img src={hackathon} className='img-fluid' height={300}/>
                                                <p className="font-weight-bold mb-1 darkBlue-text">Hackathon</p>
                                                <span className="h2 font-weight-bold mb-0 darkBlue-text">BIZZATHON</span>
                                            </div> 
                                            <div className='text-center'>
                                                <Link to="/hackathon" className='btn btn-md custom-btn mt-3'>Know More</ Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Events