import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import Navbar from './Navbar';
import upiqr from '../../src/assets/img/upi.jpeg'
function Registration() {
    const [submitButton, setSubmitButton] = useState('SUBMIT');
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [inputField, setInputField] = useState({
        college: "",
        facultyName: "",
        facultyNumber: "",
        codingParticipantOne: "",
        codingOne: "",
        codingParticipantTwo: "",
        codingTwo: "",
        designingParticipantOne: "",
        designOne: "",
        designingParticipantTwo: "",
        designTwo: "",
        communicationParticipantOne: "",
        commOne: "",
        communicationParticipantTwo: "",
        commTwo: "",
        gamingParticipant: "",
        gameOne: "",
        hackathonParticipantOne: "",
        hackOne: "",
        hackathonParticipantTwo: "",
        hackTwo: "",
        utrNumber: "" // Added field for UTR number
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        try {
            const connectionUrl = 'https://sheet.best/api/sheets/2f5ffb75-716b-4ed4-8e84-e7663697796d';

            const requestBody = {
                "Name of college": data.college,
                "Faculty Incharge": data.facultyName,
                "Faculty Mobile No": data.facultyNumber,
                "Coding Participant One": data.codingParticipantOne,
                "Coding Participant One Mobile": data.codingOne,
                "Coding Participant Two": data.codingParticipantTwo,
                "Coding Participant Two Mobile": data.codingTwo,
                "Designing Participant One": data.designingParticipantOne,
                "Designing Participant One Mobile": data.designOne,
                "Communication Participant One": data.communicationParticipantOne,
                "Communication Participant One Mobile": data.commOne,
                "Communication Participant Two": data.communicationParticipantTwo,
                "Communication Participant Two Mobile": data.commTwo,
                "Gaming Participant": data.gamingParticipant,
                "Gaming Participant Mobile": data.gameOne,
                "Hackathon Participant One": data.hackathonParticipantOne,
                "Hackathon Participant One Mobile": data.hackOne,
                "Hackathon Participant Two": data.hackathonParticipantTwo,
                "Hackathon Participant Two Mobile": data.hackTwo,
                "Transaction UTR": data.utrNumber // Added UTR number to the request body
            };

            const response = await axios.post(connectionUrl, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {
                setSubmitButton('SUBMIT');
                toast.success('Team has been registered successfully');
                reset();
                setDisableSubmitButton(false);
            } else {
                setSubmitButton('SUBMIT');
                toast.error('Sorry, something went wrong!');
                setDisableSubmitButton(false);
            }
        } catch (e) {
            setSubmitButton('SUBMIT');
            console.log(e)
            toast.error('Sorry, something went wrong!');
            setDisableSubmitButton(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <Navbar />
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12">
                        <h2 className="text-center mb-4">Event Registration Form</h2>
                        <ul className='border rounded p-5'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                <h4 className="mb-4 darkBlue-txt">General Instructions :-</h4>
                            <li>Exclusively for the BCA students.</li>
                            <li>Participants must be in venue - KLE's BCA P C Jabin Science College Hubli on 15 June, 2024 by 8:30 am.</li>
                            <li>Last Date for registration: <span className='text-danger fw-bold'>14 June, 2024.</span> </li>
                            <li>It's mandatory to register as a team of 8 participants.</li>
                            <li>Participants must carry their college ID Card.</li>
                            <li>Same participants cannot participate in more than one event.</li>
                            <li>Misconduct/Behaviour will result in immediate disqualification.</li>
                            <li>Judges' decision will be final.</li>
                            <li>The college reserves the right to make any changes if the situation demands.</li>
                            <li>Only one faculty member can accompany the team.</li>
                            <li>Fees per team : <span className='text-danger fw-bold'> Rs.1500/-</span> </li>
                                </div>
                                <div className='col-lg-6 d-flex justify-content-center align-items-center'>
                                <img src={upiqr} height={250} width={250} />

                                </div>

                            </div>


                        </ul>

                        <form className="p-4 bg-light border mb-4" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row g-3">
                                <h3 className='text-center'>College Details</h3>
                                {/* College Details */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <label htmlFor="inputcollege" className="form-label">Name Of College <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.college ? 'is-invalid' : ''}`} id="inputcollege" {...register('college', { required: true })} onChange={(e) => setInputField({ ...inputField, college: e.target.value })} />
                                    <div className="invalid-feedback">{errors.college?.type === 'required' && 'College name is required'}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <label htmlFor="inputfacultyName" className="form-label">Name of Faculty Incharge <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.facultyName ? 'is-invalid' : ''}`} id="inputfacultyName" {...register('facultyName', { required: true })} onChange={(e) => setInputField({ ...inputField, facultyName: e.target.value })} />
                                    <div className="invalid-feedback">{errors.facultyName?.type === 'required' && 'Faculty name is required'}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <label htmlFor="inputfacultyNumber" className="form-label">Faculty Incharge Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.facultyNumber ? 'is-invalid' : ''}`} id="inputfacultyNumber" {...register('facultyNumber', { required: true })} onChange={(e) => setInputField({ ...inputField, facultyNumber: e.target.value })} />
                                    <div className="invalid-feedback">{errors.facultyNumber?.type === 'required' && 'Faculty mobile number is required'}</div>
                                </div>
                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Coding Participants</h3>
                                {/* Coding Participant One */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcodingParticipantOne" className="form-label">Participant One <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.codingParticipantOne ? 'is-invalid' : ''}`} id="inputcodingParticipantOne" {...register('codingParticipantOne', { required: true })} onChange={(e) => setInputField({ ...inputField, codingParticipantOne: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcodingOne" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.codingOne ? 'is-invalid' : ''}`} id="inputcodingOne" {...register('codingOne', { required: true })} onChange={(e) => setInputField({ ...inputField, codingOne: e.target.value })} />
                                </div>
                                {/* Coding Participant Two */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcodingParticipantTwo" className="form-label">Participant Two <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.codingParticipantTwo ? 'is-invalid' : ''}`} id="inputcodingParticipantTwo" {...register('codingParticipantTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, codingParticipantTwo: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcodingTwo" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.codingTwo ? 'is-invalid' : ''}`} id="inputcodingTwo" {...register('codingTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, codingTwo: e.target.value })} />
                                </div>
                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Designing Participants</h3>
                                {/* Designing Participant One */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputdesigningParticipantOne" className="form-label">Participant One <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.designingParticipantOne ? 'is-invalid' : ''}`} id="inputdesigningParticipantOne" {...register('designingParticipantOne', { required: true })} onChange={(e) => setInputField({ ...inputField, designingParticipantOne: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputdesignOne" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.designOne ? 'is-invalid' : ''}`} id="inputdesignOne" {...register('designOne', { required: true })} onChange={(e) => setInputField({ ...inputField, designOne: e.target.value })} />
                                </div>
                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Communication Participants</h3>
                                {/* Communication Participant One */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcommunicationParticipantOne" className="form-label">Participant One <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.communicationParticipantOne ? 'is-invalid' : ''}`} id="inputcommunicationParticipantOne" {...register('communicationParticipantOne', { required: true })} onChange={(e) => setInputField({ ...inputField, communicationParticipantOne: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcommOne" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.commOne ? 'is-invalid' : ''}`} id="inputcommOne" {...register('commOne', { required: true })} onChange={(e) => setInputField({ ...inputField, commOne: e.target.value })} />
                                </div>
                                {/* Communication Participant Two */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcommunicationParticipantTwo" className="form-label">Participant Two <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.communicationParticipantTwo ? 'is-invalid' : ''}`} id="inputcommunicationParticipantTwo" {...register('communicationParticipantTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, communicationParticipantTwo: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputcommTwo" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.commTwo ? 'is-invalid' : ''}`} id="inputcommTwo" {...register('commTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, commTwo: e.target.value })} />
                                </div>
                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Gaming Participant</h3>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputgamingParticipant" className="form-label">Participant <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.gamingParticipant ? 'is-invalid' : ''}`} id="inputgamingParticipant" {...register('gamingParticipant', { required: true })} onChange={(e) => setInputField({ ...inputField, gamingParticipant: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputgameOne" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.gameOne ? 'is-invalid' : ''}`} id="inputgameOne" {...register('gameOne', { required: true })} onChange={(e) => setInputField({ ...inputField, gameOne: e.target.value })} />
                                </div>
                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Hackathon Participants</h3>
                                {/* Hackathon Participant One */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputhackathonParticipantOne" className="form-label">Participant One <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.hackathonParticipantOne ? 'is-invalid' : ''}`} id="inputhackathonParticipantOne" {...register('hackathonParticipantOne', { required: true })} onChange={(e) => setInputField({ ...inputField, hackathonParticipantOne: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputhackOne" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.hackOne ? 'is-invalid' : ''}`} id="inputhackOne" {...register('hackOne', { required: true })} onChange={(e) => setInputField({ ...inputField, hackOne: e.target.value })} />
                                </div>
                                {/* Hackathon Participant Two */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputhackathonParticipantTwo" className="form-label">Participant Two <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.hackathonParticipantTwo ? 'is-invalid' : ''}`} id="inputhackathonParticipantTwo" {...register('hackathonParticipantTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, hackathonParticipantTwo: e.target.value })} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                                    <label htmlFor="inputhackTwo" className="form-label">Mobile No <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.hackTwo ? 'is-invalid' : ''}`} id="inputhackTwo" {...register('hackTwo', { required: true })} onChange={(e) => setInputField({ ...inputField, hackTwo: e.target.value })} />
                                </div>

                                <hr />

                                <h3 className='text-center mt-5 mb-3'>Transaction Details</h3>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <label htmlFor="inpututrNumber" className="form-label">Enter UTR Number <span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${errors.utrNumber ? 'is-invalid' : ''}`} id="inpututrNumber" {...register('utrNumber', { required: true })} onChange={(e) => setInputField({ ...inputField, utrNumber: e.target.value })} />
                                    <div className="invalid-feedback">{errors.utrNumber?.type === 'required' && 'UTR number is required'}</div>
                                </div>
                                <hr />

                                <div className="col-12">
                                    <div className="text-center">
                                        <button type="submit" className="btn custom-btn">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default Registration;
